/**
 * Theme: Bullkit - Responsive Bootstrap 5 Admin Dashboard
 * Author: Mannatthemes
 * Module/App: Main Js
 */


document.querySelectorAll('.navigation-menu a').forEach(function(element){
    var pageUrl = window.location.href;
    console.log(pageUrl);

    if (element.href == pageUrl || (!pageUrl.includes('html') && element.href.includes('index'))) {
        element.parentElement.classList.add('active');
    }
    });


 var tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'))
 var tooltipList = tooltipTriggerList.map(function (tooltipTriggerEl) {
 return new bootstrap.Tooltip(tooltipTriggerEl)
 });

 //Menu
 // Toggle menu
 function toggleMenu() {
     document.getElementById('mobileToggle').classList.toggle('open');
     var isOpen = document.getElementById('navigation')
     if (isOpen.style.display === "block") {
         isOpen.style.display = "none";
     } else {
         isOpen.style.display = "block";
     }
 };




